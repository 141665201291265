var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { get, patch, post } from "../common/web";
import { getCookie } from "../workerSpace/common";
export const loginUser = (username, password) => post(`/api/user/login/`, { username, password });
export const getUserMessages = () => get(`/api/user/messages/`);
export const getWorkingGroup = () => get(`/api/user/working-group/`);
export const fetchNotifications = () => get(`/api/user/notifications/`);
// TODO not working on admin pages because window.crsf is empty
export const updateNotification = (notificationId, redirectUrl) => __awaiter(void 0, void 0, void 0, function* () { return patch(`/api/user/notifications/${notificationId}/mark-as-read/`, { redirectUrl }); });
export const updateNotificationAll = (notificationId, redirectUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(`/api/user/notifications/${notificationId}/mark-as-read/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    if (redirectUrl) {
        window.location.href = redirectUrl;
    }
    return response.json(); // Return the response for further processing if needed.
});
