import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { createRoot } from "react-dom/client";
import { DialogProvider } from "../common/components/dialog-context";
import { noOp } from "../common/util/function";
import { get } from "../common/web";
export const loadReactNode = (node, { divId = "app", theme } = {}) => {
    // Get token for MUI premium package
    get("/api/mui-token/")
        .then((res) => LicenseInfo.setLicenseKey(res.token))
        .catch(noOp);
    document.addEventListener("DOMContentLoaded", () => {
        const container = document.querySelector(`#${divId}`);
        const root = createRoot(container);
        // Providers ordering is crucial, logic for theming could be elsewhere though
        const Inner = () => _jsx(DialogProvider, { children: node });
        root.render(theme ? (_jsx(ThemeProvider, { theme: theme, children: _jsx(Inner, {}) })) : (_jsx(Inner, {})));
    });
};
