import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState } from "react";
import { CustomAlertDialog } from "./alert";
const DialogContext = createContext(undefined);
export const useDialog = () => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error("useDialog must be used within a DialogProvider");
    }
    return context;
};
export const DialogProvider = ({ children }) => {
    const [alertProps, setAlertProps] = useState();
    const handleClose = useCallback(() => {
        setAlertProps(undefined);
    }, []);
    const showAlert = useCallback((message) => {
        setAlertProps({
            message,
            onConfirm: () => handleClose(),
            onClose: () => handleClose(),
            confirmButtonText: "OK",
        });
    }, [handleClose]);
    const showConfirm = useCallback((message) => new Promise((resolve) => {
        setAlertProps({
            message,
            onConfirm: () => {
                resolve(true);
                handleClose();
            },
            onCancel: () => {
                resolve(false);
                handleClose();
            },
            onClose: () => {
                resolve(false);
                handleClose();
            },
            cancelButtonText: "Annuler",
            confirmButtonText: "Confirmer",
        });
    }), [handleClose]);
    return (_jsxs(DialogContext.Provider, { value: { showAlert, showConfirm }, children: [children, alertProps && _jsx(CustomAlertDialog, Object.assign({ open: !!alertProps }, alertProps))] }));
};
