import { padOneIf } from "../../util/string";
export const personToString = (p) => `${p.firstname} ${p.surname.toUpperCase()}`;
export const clientLabel = (c) => `${c.code} ${c.name}`;
/** Returns empty string if data insufficent to display name */
export function getContactName(contact, withRole = false) {
    const { title, surname, firstname, role } = contact;
    if ((title && surname) || (surname && firstname)) {
        return `${padOneIf(title !== null && title !== void 0 ? title : undefined)}${padOneIf(firstname)}${surname === null || surname === void 0 ? void 0 : surname.toUpperCase()}${padOneIf(role, withRole, " - ")}`;
    }
    return "";
}
