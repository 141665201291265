var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { api, get } from "./web";
export function getMediaUri(url) {
    return __awaiter(this, void 0, void 0, function* () {
        return api("/api/to-media-uri/", { url });
    });
}
export var GetClientsFilter;
(function (GetClientsFilter) {
    GetClientsFilter["MENU"] = "menu";
    GetClientsFilter["GED"] = "ged";
})(GetClientsFilter || (GetClientsFilter = {}));
export var GetClientsDepth;
(function (GetClientsDepth) {
    GetClientsDepth["DISPLAY"] = "display";
})(GetClientsDepth || (GetClientsDepth = {}));
export function getClients({ filter, depth, } = {}) {
    return get("/api/clients/", {
        filter,
        depth,
    });
}
export function getClient(id) {
    return get(`/api/clients/${id}/`);
}
export function getClientDisplay(clientId) {
    return __awaiter(this, void 0, void 0, function* () {
        return api(`/api/clients/${clientId}/display/`);
    });
}
export const getUser = () => get(`/api/user/`);
