export function getInitials(firstname, lastname) {
    return `${firstname.charAt(0)}${lastname.charAt(0)}`.toUpperCase();
}
export function toTitleCase(str) {
    return str.replace(/[^\W_]+[^\s-]*/g, (w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase());
}
export function padOneIf(str, extraIf, padStart) {
    if (!str)
        return "";
    let res = str.padEnd(str.length + 1);
    if (padStart)
        res = res.padStart(padStart.length + res.length, padStart);
    if (extraIf !== undefined)
        return extraIf ? res : "";
    return res;
}
export function camelToSnakeCase(str) {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}
export function camelToTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/[A-Z]|(\d+)/g, (c) => ` ${c.toUpperCase()}`);
}
