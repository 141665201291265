export function sortWidget(listWidget) {
    const list = [...listWidget];
    for (let i = 0; i < list.length; i += 1) {
        for (let j = 0; j < list.length - 1; j += 1) {
            if (list[j + 1].line < list[j].line) {
                const widget = list[j];
                list[j] = list[j + 1];
                list[j + 1] = widget;
            }
            else if (list[j + 1].line === list[j].line && list[j + 1].position < list[j].position) {
                const widget = list[j];
                list[j] = list[j + 1];
                list[j + 1] = widget;
            }
        }
    }
    return list;
}
/** If cookies not available or not found returns empty string */
export function getCookie(name) {
    if (document.cookie) {
        const cookie = document.cookie
            .split(";")
            .map((c) => c.trim())
            .filter((c) => c.startsWith(`${name}=`));
        if (cookie.length > 0)
            return decodeURIComponent(cookie[0].split("=")[1]);
    }
    return "";
}
