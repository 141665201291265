import moment from "moment";
import { formatTwoDigits } from "../number";
import { DateQuantity } from "./index";
export class MomentUtils {
    constructor() { }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    shortDisplay(date) {
        return moment(date).format("ddd, hA");
    }
    fullDisplay(date) {
        return moment(date).format("MMM D, YYYY · h:mm A");
    }
    dayMonthDisplay(date) {
        return moment(date).format("DD/MM");
    }
    monthYearDisplay(date) {
        return moment(date).format("MM/YY");
    }
    monthLongYearDisplay(date) {
        return moment(date).format("MM/YYYY");
    }
    fromNowDisplay(date) {
        return moment(date).fromNow();
    }
    onlyDateDisplay(date) {
        return moment(date).format("DD/MM/YYYY");
    }
    onlyIsoDateDisplay(date) {
        return moment(date).toISOString(true).split("T")[0];
    }
    fullMonthDisplay(dateString) {
        const date = moment(dateString);
        const formatedDate = date.locale("fr").format("DD MMMM YYYY");
        return formatedDate;
    }
    daysToNow(date) {
        return moment().diff(moment(date), DateQuantity.DAYS);
    }
    weeksToNow(date) {
        return moment().endOf(DateQuantity.WEEKS).diff(moment(date), DateQuantity.WEEKS);
    }
    isInLast(date, n, q) {
        return moment(date).isSameOrAfter(moment().subtract(n, q));
    }
    weekNumber(date) {
        return moment(date).isoWeek();
    }
    weekDayNumber(date) {
        return moment(date).isoWeekday();
    }
    dayOfYearNumber(date) {
        return moment(date).dayOfYear();
    }
    startOf(date, q) {
        return moment(date).startOf(q).toDate();
    }
    endOf(date, q) {
        return moment(date).endOf(q).toDate();
    }
    alter(date, n, q) {
        return moment(date).add(n, q).toDate();
    }
    equals(date1, date2, q = DateQuantity.SECONDS) {
        return moment(date1).isSame(date2, q);
    }
    isAfter(date1, date2, q = DateQuantity.SECONDS) {
        return moment(date1).isAfter(date2, q);
    }
    isSameOrAfter(date1, date2, q = DateQuantity.SECONDS) {
        return moment(date1).isSameOrAfter(date2, q);
    }
    fromWeekNumber(week, year) {
        const y = year !== null && year !== void 0 ? year : moment().year();
        return moment(`${y}W${formatTwoDigits(week)}`).toDate();
    }
    /**
     * @param date
     * @param withMilliseconds True by default
     */
    toTimestamp(date, withMilliseconds = true) {
        if (withMilliseconds)
            return moment(date).valueOf();
        return moment(date).unix();
    }
    toDate(date) {
        return moment(date).toDate();
    }
    now() {
        return moment().toDate();
    }
}
