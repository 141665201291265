var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const constantFn = (value) => () => value;
export const identity = (v) => v;
export const noOp = () => { };
/** Warning: this is a handy cast of anything into a promise but might have unexpected effect on Thenable objects */
export const promise = (p) => Promise.resolve(p);
export const ignoreVoidPromise = (f, ...args) => {
    promise(f(...args))
        .then(noOp)
        .catch(noOp);
};
/** Shorthand writing for "Using callback returning void which effect will not be awaited if promise" */
export const voidCb = (f, ...args) => () => ignoreVoidPromise(f, ...args);
/** Only await the promise if condition is true while ignoring its return value */
export const ignorePromiseIf = (condition, p) => __awaiter(void 0, void 0, void 0, function* () {
    if (condition)
        yield p;
});
