import { isNumber } from "../util/number";
export var UserRole;
(function (UserRole) {
    UserRole[UserRole["SUPER_USER"] = 1] = "SUPER_USER";
    UserRole[UserRole["EXPERT"] = 2] = "EXPERT";
    UserRole[UserRole["ADMINISTRATIVE"] = 3] = "ADMINISTRATIVE";
    UserRole[UserRole["GROUP_MANAGER"] = 4] = "GROUP_MANAGER";
    UserRole[UserRole["TECH_MANAGER"] = 5] = "TECH_MANAGER";
    UserRole[UserRole["FILE_MANAGER"] = 6] = "FILE_MANAGER";
    UserRole[UserRole["LEGAL_MANAGER"] = 7] = "LEGAL_MANAGER";
    UserRole[UserRole["SOCIAL_MANAGER"] = 8] = "SOCIAL_MANAGER";
    UserRole[UserRole["SECRETARY"] = 9] = "SECRETARY";
    UserRole[UserRole["HUMAN_RESOURCES"] = 10] = "HUMAN_RESOURCES";
    UserRole[UserRole["IT_DEPARTMENT"] = 11] = "IT_DEPARTMENT";
})(UserRole || (UserRole = {}));
export const userRoleLabels = {
    [UserRole.SUPER_USER]: ["Administrateur", "SU"],
    [UserRole.EXPERT]: ["Expert-comptable / Commissaire aux comptes", "EC"],
    [UserRole.ADMINISTRATIVE]: ["Administratif", "AD"],
    [UserRole.GROUP_MANAGER]: ["Responsable de groupe", "RG"],
    [UserRole.TECH_MANAGER]: ["Responsable technique", "RT"],
    [UserRole.FILE_MANAGER]: ["Responsable de dossier", "RD"],
    [UserRole.LEGAL_MANAGER]: ["Responsable juridique", "RJ"],
    [UserRole.SOCIAL_MANAGER]: ["Responsable social", "RS"],
    [UserRole.SECRETARY]: ["Secrétariat", "SC"],
    [UserRole.HUMAN_RESOURCES]: ["Ressources humaines", "RH"],
    [UserRole.IT_DEPARTMENT]: ["Service Informatique", "IT"],
};
/** Typescript add reverse mapping to int enums so filter out values to only get integers */
export const noRoles = Object.fromEntries(Object.values(UserRole).filter((v) => isNumber(v)).map((v) => [v, false]));
export function generateRoleHierarchy(order) {
    return (roles, test) => {
        if (roles === null)
            return false;
        if (roles[test])
            return true;
        let i = order.indexOf(test) + 1;
        if (i > 0) {
            while (i < order.length) {
                if (roles[order[i]])
                    return true;
                i += 1;
            }
        }
        return false;
    };
}
export const workingGroupLabel = {
    [UserRole.TECH_MANAGER]: "groupedetravailrg",
    [UserRole.LEGAL_MANAGER]: "groupedetravailrj",
    [UserRole.SOCIAL_MANAGER]: "groupedetravailrs",
};
