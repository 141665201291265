import { jsx as _jsx } from "react/jsx-runtime";
import { Alert, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import { noOp } from "../common/util/function";
import { getUserMessages } from "./request";
export const MessagesDialog = () => {
    const [open, setOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        getUserMessages()
            .then((res) => {
            if (res && res.length > 0) {
                setMessages(res);
                return setOpen(true);
            }
            return false;
        })
            .catch(noOp);
    }, []);
    return (_jsx(Dialog, { open: open, onClose: () => setOpen(false), children: _jsx(DialogContent, { children: _jsx(Grid, { container: true, direction: "column", spacing: 1, children: messages.map(({ message, level }, i) => (_jsx(Grid, { item: true, children: _jsx(Alert, { severity: level, children: _jsx(Typography, { children: HTMLReactParser(message) }) }) }, i))) }) }) }));
};
