export function toFixedDisplay(n, fractionDigits = 2) {
    const parts = n.toFixed(fractionDigits).split(".");
    parts[0] = parts[0].replaceAll(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}
/**
 * @param n number to round
 * @param nd number of decimal to roud at. Default is 0.
 */
export function roundAt(n, nd = 0) {
    const x = Math.pow(10, nd);
    return Math.round(n * x) / x;
}
export const formatTwoDigits = (n) => (n < 10 ? `0${n.toString()}` : n.toString());
const numberToString = (x) => x.toString();
export const withSign = (x, displayFn = numberToString) => `${x > 0 ? "+" : ""}${displayFn(x)}`;
/**
 * Subjective way of rounding number and adding quantity suffix
 * @param x
 */
export const shortenNumber = (x) => {
    const rounded = Math.round(x);
    const absolute = Math.abs(rounded);
    if (absolute < 10000)
        return toFixedDisplay(rounded, 0);
    let pair = ["k", 1000];
    if (absolute >= 10000000)
        pair = ["M", 1000000];
    const [suffix, factor] = pair;
    return `${toFixedDisplay(rounded / factor, 0)} ${suffix}`;
};
export const parseNumberOrZero = (v) => {
    if (typeof v === "number")
        return v;
    if (typeof v === "string")
        return Number.parseFloat(v) || 0;
    return 0;
};
export const isNumber = (v) => !Number.isNaN(Number.parseInt(v, 10));
