import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Avatar, Box, CssBaseline, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getUser } from "../common/request";
import { personToString } from "../common/types/client";
import { workingGroupLabel } from "../common/types/user";
import { noOp } from "../common/util/function";
import { getInitials } from "../common/util/string";
import { MessagesDialog } from "./backend-messages";
import { CustomLogin } from "./login";
import "./main.css";
import { NotificationsButton } from "./notifications";
import { getWorkingGroup } from "./request";
import { SidebarMenu } from "./sidebar-menu";
const drawerWidth = 240;
export function MainMenu() {
    const [isDrawerOpen, setDrawerOpen] = useState(window.location.pathname === "/");
    const [anchorEl, setAnchorEl] = useState();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [workingGroup, setWorkingGroup] = useState();
    const isMenuOpen = Boolean(anchorEl);
    useEffect(() => {
        setLoading(true);
        getUser()
            .then((res) => {
            setUser(res);
            return setLoading(false);
        })
            .catch(() => setLoading(false));
    }, []);
    useEffect(() => {
        if (user)
            getWorkingGroup()
                .then(({ workingGroup: wg }) => wg && setWorkingGroup(wg))
                .catch(noOp);
    }, [user]);
    const openDrawer = () => {
        setDrawerOpen(true);
    };
    const closeDrawer = () => {
        setDrawerOpen(false);
    };
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(undefined);
    };
    const renderMenu = (_jsxs(Menu, { anchorEl: anchorEl, anchorOrigin: { vertical: "top", horizontal: "right" }, keepMounted: true, transformOrigin: { vertical: "top", horizontal: "right" }, open: isMenuOpen, onClose: handleMenuClose, children: [_jsx(MenuItem, { disabled: true, divider: true, children: user && personToString(user) }), [
                [
                    "Groupe de travail",
                    workingGroup ? `/gestion_api/${workingGroupLabel[workingGroup.type]}/${workingGroup.id}/` : "",
                    false,
                    !workingGroup,
                ],
                ["Compétences linguistiques", "/worker-languages/"],
                ["Téléchargements", "/user-downloads/", true],
                ["Configuration espace", "/my-space/config/", true],
                ["Déconnexion", "/logout/"],
            ]
                .filter((parts) => !parts[3])
                .map(([label, link, withDivider]) => (_jsx(MenuItem, { divider: withDivider, component: "a", href: link, children: label }, label)))] }));
    return (_jsxs(_Fragment, { children: [_jsx(CssBaseline, {}), _jsx(AppBar, { position: "absolute", sx: [
                    (theme) => ({
                        backgroundColor: "#66c5db",
                        color: "black",
                        zIndex: theme.zIndex.drawer + 1,
                        transition: theme.transitions.create(["width", "margin"], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                    }),
                    isDrawerOpen &&
                        ((theme) => ({
                            marginLeft: drawerWidth,
                            width: `calc(100% - ${drawerWidth}px)`,
                            transition: theme.transitions.create(["width", "margin"], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.enteringScreen,
                            }),
                        })),
                ], children: _jsxs(Toolbar, { sx: { pr: 3 }, children: [_jsx(IconButton, { disabled: !user, edge: "start", color: "inherit", onClick: openDrawer, sx: [{ mr: 4 }, isDrawerOpen && { display: "none" }], size: "large", children: _jsx(MenuIcon, {}) }), _jsx(Typography, { component: "h1", variant: "h6", color: "inherit", noWrap: true, sx: { flexGrow: 1 }, children: document.title }), user && _jsx(NotificationsButton, {}), user && (_jsx(IconButton, { color: "inherit", onClick: handleProfileMenuOpen, size: "large", children: _jsx(Avatar, { sx: (theme) => ({
                                    color: theme.palette.getContrastText("#033860"),
                                    backgroundColor: "#033860",
                                }), children: user && getInitials(user.firstname, user.surname) }) }))] }) }), user && _jsx(SidebarMenu, { open: isDrawerOpen, onClose: closeDrawer, width: drawerWidth, user: user }), !user && !loading && (_jsx(Box, { mt: 16, mx: "auto", children: _jsx(CustomLogin, {}) })), renderMenu, _jsx(MessagesDialog, {})] }));
}
