export function keyboardCallback(code, cb) {
    let cursor = 0;
    document.addEventListener("keydown", (e) => {
        if (e.key === code[cursor])
            cursor += 1;
        else
            cursor = e.key === code[0] ? 1 : 0;
        if (cursor === code.length) {
            cb();
            cursor = 0;
        }
    });
}
