import { formatTwoDigits } from "../number";
export var DateQuantity;
(function (DateQuantity) {
    DateQuantity["MILLISECONDS"] = "milliseconds";
    DateQuantity["SECONDS"] = "seconds";
    DateQuantity["MINUTES"] = "minutes";
    DateQuantity["HOURS"] = "hours";
    DateQuantity["DAYS"] = "days";
    DateQuantity["WEEKS"] = "weeks";
    DateQuantity["MONTHS"] = "months";
    DateQuantity["YEARS"] = "years";
})(DateQuantity || (DateQuantity = {}));
/**
 * Transforms float hour to object with hour, minute and second counts
 */
export function parseFloatHour(f) {
    const hours = Math.floor(f);
    const rest = f % 1;
    const minutes = Math.floor(rest * 60);
    const seconds = Math.floor((rest * 60 - minutes) * 60);
    return { hours, minutes, seconds };
}
/**
 * Transforms float hour to string formatted as '09:00:00' or '13:35:25' round up to the second
 */
export function formatHour(f) {
    const { hours, minutes, seconds } = parseFloatHour(f);
    return `${formatTwoDigits(hours)}:${formatTwoDigits(minutes)}:${formatTwoDigits(seconds)}`;
}
